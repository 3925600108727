export default function IconClose() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-0.75 -0.75 16 16"
      id="Delete-Circle--Streamline-Micro"
      height="100%"
      width="100%"
    >
      <desc>{'Delete Circle Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9.425 5.075000000000001 -4.3500000000000005 4.3500000000000005"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m5.075000000000001 5.075000000000001 4.3500000000000005 4.3500000000000005"
        strokeWidth={1.5}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.250000000000001 13.775000000000002a6.525 6.525 0 1 0 0 -13.05 6.525 6.525 0 0 0 0 13.05Z"
        strokeWidth={1.5}
      />
    </svg>
  );
}
